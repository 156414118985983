import React, { useState, Fragment, useEffect } from "react";
import { RangeDatePicker } from "@y0c/react-datepicker";
import PageTitle from "../../../layouts/PageTitle";
import { GetCurrentAuthState } from "../../../../services";
import * as XLSX from 'xlsx';
import toast, { Toaster } from 'react-hot-toast';
import { TbBellRinging } from "react-icons/tb";
import "./Exports.css"
const Exports = () => {

  const [currentDocuments, setCurrentDocuments] = useState('users')
  const [date, setDate] = useState([])
  const userData = GetCurrentAuthState()
  // console.log(userData);
  const [selectedOption, setSelectedOption] = useState(userData.kiosk[0].kioskId)
  const handleDateChange = val => (...args) => {
    // console.log(val, args);
    setDate(args)
  }

  const changeDocuments = (data) => {
    setCurrentDocuments(data.target.value)
  }

  const handleKioskId = (e) => {
    setSelectedOption(e.target.value)
  }

  const handleClick = async () => {
    if (date[0] && date[1]) {
      // console.log(date[0]);
      // console.log(date[1]);
      var start = new Date(date[0]).toISOString()
      // console.log(start);
      var end = new Date(date[1]).toISOString()
      // console.log(end);
      // console.log(currentDocuments);
      // console.log(selectedOption);
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/trends/certaindocuments/${start}/${end}/${currentDocuments}/${selectedOption}`);
        const data = await response.json();
        // console.log(data.data);
        if (currentDocuments === "users") {
          const worksheet = XLSX.utils.json_to_sheet(data.data);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          XLSX.writeFile(workbook, `${currentDocuments}.xlsx`);
        } else if (currentDocuments === "reports") {
          data.data.map((item) => {
            item.name = item.patientId.name
            item.phone = item.patientId.phone
            delete item.patientId
          })
          const worksheet = XLSX.utils.json_to_sheet(data.data);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          XLSX.writeFile(workbook, `${currentDocuments}.xlsx`);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast('Enter All Values', {
        icon: <TbBellRinging />,
        duration: "200",
      });
    }


  }
  return (
    <Fragment>
      <PageTitle activeMenu="Exports" motherMenu="Form" pageContent="Exports" />
      <div className='toastContent'>
        <Toaster toastOptions={{ className: "toast" }} position="top-right" reverseOrder={false} />
      </div>
      <div className="row">
        <div style={{ height: "500px" }}>
          <div className="col-xl-12 col-lg-8">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Select Data</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 mb-1">
                    <div style={{ display: "flex" }}>
                      <div className="example rangeDatePicker" style={{ width: "100%" }} >
                        <p className="mb-1"> Duration</p>
                        <RangeDatePicker
                          startPlaceholder="Start Date"
                          endPlaceholder="End Date"
                          onChange={handleDateChange("text")}
                        />
                      </div>
                      <div className="example rangeDatePicker ml-3" style={{ width: "80%" }}>
                        <p className="mb-1"> Documents</p>
                        <select name="cars" id="cars" value={currentDocuments} onChange={(data) => changeDocuments(data)} >
                          <option value="users">Users</option>
                          <option value="reports">Reports</option>
                          {/* <option value="consultations">Consultations</option> */}
                        </select>
                      </div>
                      <div className="example rangeDatePicker ml-3" style={{ width: "100%" }}>
                        {userData.kiosk.length >= 2 && (
                          // <div>
                          <div className='w-100'>
                            <p> Kiosk  </p>
                            <select
                              value={selectedOption}
                              onChange={e => { handleKioskId(e) }}
                              style={{ height: "56px" }}
                              id="cars"
                              className="w-100 p-2 rounded-lg">
                              {userData.kiosk.map((x, i) => (
                                <option key={i} value={x.kioskId}>{x.kioskId} - {x.locationName ? x.locationName : x.location}</option>
                              ))}
                            </select>
                          </div>
                          // </div>
                        )}
                      </div>
                    </div>
                    {/* <JsonToExcel
                      title="Download"
                      data={download}
                      fileName="sample-file"
                    /> */}
                    <button className="btn btn-primary btn-block mt-3" onClick={() => handleClick()}>Download</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Exports;
